import React, { Component } from "react";
import PietersradeLogo from "../../../images/Pietersrade-logo.png";
import Graan from "../../../images/graan.jpg";
import Divider from "material-ui/Divider";
import { withRouter, WithRouterProps } from "react-router";
import Header from "../../ledenPortaal/header/homeHeader";
import { colorsBCV } from "../../../styling";
import { FloatingFooter } from "../../../components/footer";
import { VerticalSeparator } from "../../../components/layout";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import Scroll from "react-scroll";
import {
  LoginButton,
  PackageInfoButton,
  ContactInfoButton,
  NotifyDeathButton,
} from "./components";
import { Link } from "@mui/material";
// @ts-ignore
import notulen from "files/2024-06-09NotulenAlgemeneLedenVergaderingConceptWebsite.pdf";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    height: "100%",
    width: "100%",
  },
  topContainer: {
    height: 600,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(" + Graan + ")",
    backgroundSize: "100% 140%",
  },
  dimmer: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  middleContainer: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  bottomContainer: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    margin: "10%",
  },
  logo: {
    width: "280px",
  },
};

class Home extends Component<WithRouterProps> {
  render() {
    return (
      <div style={styles.container}>
        <Header />
        <FloatingFooter />
        <div style={styles.topContainer}>
          <div style={styles.dimmer}>
            <div
              style={{
                paddingTop: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img style={styles.logo} src={PietersradeLogo} alt="" />
              <VerticalSeparator distance={4} />
              <LoginButton />

              <VerticalSeparator distance={4} />
              <h3 style={{ color: "white", textAlign: "center" }}>
                Bent u al lid en wilt u een account waarmee u uw gegevens kunt
                beheren?
                <br />
                Neem dan contact op via{" "}
                <a
                  style={{ color: "white" }}
                  href="mailto:st.pietersrade@home.nl"
                >
                  st.pietersrade@home.nl
                </a>{" "}
                onder vermelding van uw geboortedatum en email adres.
              </h3>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "-28px",
          }}
        >
          <FloatingActionButton
            onClick={() => {
              Scroll.animateScroll.scrollTo(500);
            }}
          >
            <ArrowDown />
          </FloatingActionButton>
        </div>

        <div
          style={{
            marginTop: "30px",
            marginBottom: "100px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={styles.middleContainer}>
            <h2 style={{ color: colorsBCV.secondary }}>
              Notulen Algemene Ledenvergadering zondag 9 juni 2024
            </h2>
            <div style={{ maxWidth: "80%" }}>
              Download de concept notulen van de ledenvergadering{" "}
              <Link
                href={notulen}
                title="2024-06-09 Notulen Algemene Leden Vergadering Concept"
                underline="always"
                target="_blank"
                fontWeight="400"
                color="secondary"
              >
                hier
              </Link>
              .
              <br />
            </div>
            <h2 style={{ color: colorsBCV.secondary }}>Onze vereniging</h2>
            <div style={{ maxWidth: "80%" }}>
              Onderlinge Begrafenis- en Crematieverenging ‘St.Pietersrade’ neemt
              bij het overlijden van u of uw naasten de uitvaartregeling alsmede
              een deel van de officiële zorgen van u over. Dit doen wij door de
              begrafenis of de crematie te laten verzorgen en uit te voeren. Wij
              werken hierbij zonder winstoogmerk.
            </div>
          </div>
        </div>
        <Divider />
        <Divider />
        <div
          style={{
            marginTop: "100px",
            marginBottom: "100px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <div style={styles.bottomContainer}>
            <div style={{ fontWeight: "bold" }}>Pakketten</div>
            <div
              style={{
                paddingTop: "15px",
                paddingBottom: "40px",
                height: "60px",
                width: "100%",
              }}
            >
              Bij St. Pietersrade bestaan twee naturavergoedingen.
            </div>
            <PackageInfoButton />
          </div>
          <div style={styles.bottomContainer}>
            <div style={{ fontWeight: "bold" }}>Lidmaatschap</div>
            <div
              style={{
                paddingTop: "15px",
                paddingBottom: "40px",
                height: "60px",
                width: "100%",
              }}
            >
              Als lid van St. Pietersade bent u verzekerd van een waardige
              uitvaart.
            </div>
            {/* TODO: This is temporary until the contribution and become member is added back into the application*/}
            <div style={{ width: 200, height: 40 }} />
          </div>
          <div style={styles.bottomContainer}>
            <div style={{ fontWeight: "bold" }}>Contact</div>
            <div
              style={{
                paddingTop: "15px",
                paddingBottom: "40px",
                height: "60px",
                width: "100%",
              }}
            >
              Neemt u gerust contact op met onze secretaris.
            </div>
            <ContactInfoButton />
          </div>
        </div>
        <Divider />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              ...styles.middleContainer,
              marginTop: "100px",
              marginBottom: "100px",
              width: "40%",
            }}
          >
            <h2 style={{ color: colorsBCV.secondary }}>Sterfgeval melden?</h2>
            <div style={{ paddingBottom: "40px" }}>
              Sterfgevallen dienen binnen 12 uur te worden gemeld.
              <br /> <br />U hoeft zelf niets te regelen. Na melding komt de
              uitvaartverzorger bij u thuis om, in overleg met u, de nodige
              voorzieningen te treffen.
            </div>
            <NotifyDeathButton />
          </div>
        </div>
      </div>
    );
  }
}

const DecoratedHome = withRouter(Home);

export default DecoratedHome;
